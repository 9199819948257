<template>
  <div class="home">
    <template v-if="active === 0">
      <div class="service_box">
        <div class="swipe_box">
          <van-swipe :autoplay="3000" indicator-color="white">
            <template v-if="swipes.length">
              <van-swipe-item :key="item.id" v-for="item in swipes">
                <img :src="item.carousel" @click="onImg(item)" alt="" />
              </van-swipe-item>
            </template>
            <van-swipe-item v-else>
              <img src="@/assets/images/home/swipe.png" alt="" />
            </van-swipe-item>
          </van-swipe>
        </div>
        <div class="nav_box">
          <template v-if="navs.length || navs2.length">
            <img
              :src="item.imgUrl"
              v-for="(item, index) in navs"
              :key="index"
              @click="onNav(item)"
              class="navs"
            />
            <div class="navs2" v-if="navs2.length">
              <img
                v-for="(item, index) in navs2"
                :key="index"
                @click="onNav(item)"
                :src="item.imgUrl"
              />
            </div>
          </template>
          <div class="nothing" v-else>
            <img src="@/assets/images/nothing_service.png" alt="" />
            <span>暂无预约服务</span>
          </div>
          <div class="feedback_box">
            <div>意见反馈</div>
            <img
              src="@/assets/images/home/feedback.png"
              @click="feedback"
              alt=""
            />
          </div>
        </div>
      </div>
    </template>
    <template v-if="active === 1">
      <div class="personal_box">
        <div class="info_box">
          <img src="@/assets/images/home/personal/user_logo.png" alt="" />
          <p>手机号：{{ $store._state.data.info.phonenumber }}</p>
          <div class="btn" @click="modify">修改</div>
        </div>
        <van-cell
          :title="item.name"
          is-link
          :icon="item.imgUrl"
          v-for="(item, index) in list"
          :key="index"
          @click="toMyTicket(item.path)"
        />
      </div>
    </template>
    <van-tabbar v-model="active">
      <img
        class="telephone_box"
        src="@/assets/images/home/Hotline.png"
        alt=""
        v-if="active === 0"
      />
      <van-tabbar-item>
        <span>预约服务</span>
        <template #icon="props">
          <img :src="props.active ? icon.active : icon.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item>
        <span>我的</span>
        <template #icon="props">
          <img :src="props.active ? icon.active2 : icon.inactive2" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
    <van-dialog
      v-model:show="show"
      v-if="show"
      title="修改手机号"
      show-cancel-button
      :beforeClose="beforeClose"
    >
      <van-form ref="infoForm">
        <van-field
          v-model="form.phone"
          placeholder="请输入手机号"
          name="phone"
          :rules="[
            {
              required: true,
              message: '请输入手机号',
            },
            {
              pattern:
                /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
              message: '请输入正确的手机号码',
            },
          ]"
        />
        <van-field
          v-model="form.code"
          center
          clearable
          placeholder="请输入验证码"
          :rules="[
            {
              required: true,
              message: '请输入验证码',
            },
          ]"
        >
          <template #button>
            <van-button
              size="small"
              type="primary"
              @click="sendCode"
              :disabled="time > 0"
              >{{
                time > 0 ? time + "s后可重新发送" : "发送验证码"
              }}</van-button
            >
          </template>
        </van-field>
      </van-form>
    </van-dialog>
    <MyNotice ref="myNotice" @agree="agree" />
    <MyTips ref="myTips" :content="content" />
    <van-popup
      v-model:show="show2"
      v-if="show2"
      position="bottom"
      round
      :close-on-click-overlay="false"
      class="binding"
    >
      <p>绑定手机号</p>
      <van-form ref="infoForm2">
        <van-field
          v-model="form2.phone"
          placeholder="请输入手机号"
          name="phone"
          :rules="[
            {
              required: true,
              message: '请输入手机号',
            },
            {
              pattern:
                /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
              message: '请输入正确的手机号码',
            },
          ]"
        />
        <van-field
          v-model="form2.code"
          center
          clearable
          placeholder="请输入验证码"
          :rules="[
            {
              required: true,
              message: '请输入验证码',
            },
          ]"
        >
          <template #button>
            <van-button
              size="small"
              type="primary"
              @click="sendCode2"
              :disabled="time2 > 0"
              >{{
                time2 > 0 ? time2 + "s后可重新发送" : "发送验证码"
              }}</van-button
            >
          </template>
        </van-field>
        <div class="btn_box">
          <!-- <van-button plain type="success" size="large" @click="show2 = false"
            >取消</van-button
          > -->
          <van-button
            type="success"
            native-type="submit"
            size="large"
            @click="onSubmit"
            >绑定</van-button
          >
        </div>
      </van-form>
    </van-popup>
    <van-popup
      v-model:show="show3"
      v-if="show3"
      position="bottom"
      round
      :close-on-click-overlay="false"
      class="binding authorization"
    >
      <p>问卷调查</p>
      <div class="btn_box">
        <div class="btn_authorization">
          <van-button type="success" class="btn_sty">
            <p>消息推送授权</p>
          </van-button>
          <div style="position: absolute; width: 100%; height: 100%; top: 0px">
            <OpenSubscribe
              :templateId="[
                'ExAOFRHgabYEXRz-bHR0WvA3wLNefvobw9OErzx33qg',
                'x210-00wPQ91mBFES0RskjR7JNcy5HJv-mZQS0o1DpU',
              ]"
              @open-subscribe-success="openSubscribeSuccess"
              @open-subscribe-error="openSubscribeError"
            >
            </OpenSubscribe>
          </div>
        </div>
        <van-button plain type="success" class="cancel" @click="cancel"
          >取消</van-button
        >
      </div>
    </van-popup>
  </div>
</template>

<script>
import MyNotice from "@/components/MyNotice.vue";
import OpenSubscribe from "@/components/OpenSubscribe.vue";
import { carouselManagementList, basic_config_get_all_close } from "@/api/api";
import { get_verify_code, bind_phone } from "@/api/login";
import { getCommonConfig } from "@/api/Reservation";
import { setToken } from "@/utils/auth";

import MyTips from "@/components/MyTips.vue";
export default {
  name: "Home",
  data() {
    return {
      api: process.env.VUE_APP_BASE_API + "/profile",
      show: false,
      form: {},
      time: null,
      timer: null,
      navs: [],
      navs2: [],
      item: {},
      swipes: [],
      content: "",
      active: 0,
      icon: {
        active: require("@/assets/images/home/service_s.png"),
        inactive: require("@/assets/images/home/service_n.png"),
        active2: require("@/assets/images/home/center_s.png"),
        inactive2: require("@/assets/images/home/center_n.png"),
      },
      list: [
        {
          name: "常用观众",
          path: "/commonlyUsed",
          imgUrl: require(`@/assets/images/home/personal/audience_c.png`),
        },
        {
          name: "门票预约记录",
          path: "/myTicket?type=门票预约记录",
          imgUrl: require(`@/assets/images/home/personal/gate_c.png`),
        },
        {
          name: "儿童科学乐园门票预约记录",
          path: "/myTicket?type=儿童科学乐园门票预约记录",
          imgUrl: require(`@/assets/images/home/personal/gate_c.png`),
        },
        {
          name: "影票购买记录",
          path: "/myTicket?type=我的影票",
          imgUrl: require(`@/assets/images/home/personal/movie_c.png`),
        },
        {
          name: "活动报名记录",
          path: "/myTicketActivity?type=活动报名记录",
          imgUrl: require(`@/assets/images/home/personal/activity_c.png`),
        },
        {
          name: "临展报名记录",
          path: "/myTicketActivity?type=临展报名记录",
          imgUrl: require(`@/assets/images/home/personal/exhibition_c.png`),
        },
      ],
      show2: false,
      form2: {},
      time2: null,
      timer2: null,
      allClose: null,
      show3: false,
    };
  },
  components: { MyNotice, MyTips, OpenSubscribe },
  created() {
    let token = this.$route.query.token;
    // let token =
    //   'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJsb2dpblR5cGUiOiJsb2dpbiIsImxvZ2luSWQiOiIwMDoxIiwicm5TdHIiOiJXVWYyWGVlamFwTVZicXlLMkx4UWFsYlY0dWVCeVdndyIsInVzZXJJZCI6MX0.MNAlJDQJfMRAwQ_XCfhmEPdlyOrGoRIQehPA2oBAEO0';
    if (token) {
      setToken(token);
      this.$store._state.data.token = token;
      location.href = location.origin + location.pathname;
    }
    this.$store.dispatch("getUserInfo").then(() => {
      if (!this.$store._state.data.info.phonenumber) {
        this.show2 = true;
      } else {
        this.authorization();
      }
    });
    this.getAllClose();
    this.getCarouselChart();
  },
  mounted() {},
  methods: {
    authorization() {
      let date = localStorage.getItem("date");
      if (!(date && date === this.getDate())) {
        this.show3 = true;
      }
    },
    // 是否闭馆
    async getAllClose() {
      let { data } = await basic_config_get_all_close();
      this.allClose = data;
      if (!this.allClose) {
        this.getAppointment();
      }
    },
    sendCode() {
      this.$refs.infoForm
        .validate("phone")
        .then(async () => {
          await get_verify_code({ phone: this.form.phone });
          this.time = 60;
          this.timer = setInterval(() => {
            this.time--;
            if (this.time <= 0) {
              clearInterval(this.timer);
            }
          }, 1000);
          // 验证通过
        })
        .catch(() => {
          //验证失败
        });
    },
    sendCode2() {
      this.$refs.infoForm2
        .validate("phone")
        .then(async () => {
          await get_verify_code({ phone: this.form2.phone });
          this.time2 = 60;
          this.timer2 = setInterval(() => {
            this.time2--;
            if (this.time2 <= 0) {
              clearInterval(this.timer2);
            }
          }, 1000);
          // 验证通过
        })
        .catch(() => {
          //验证失败
        });
    },
    async onSubmit() {
      this.$refs.infoForm2
        .validate()
        .then(async () => {
          await bind_phone(this.form2);
          this.show2 = false;
          this.form2 = {};
          this.$store.dispatch("getUserInfo");
          this.authorization();
        })
        .catch(() => {
          return false;
        });
    },
    async getAppointment() {
      this.navs = [];
      this.navs2 = [];
      this.navs = this.navs.concat([
        {
          name: "个人预约",
          btn: "立即预约",
          path: "/ticketReservation",
          imgUrl: require(`@/assets/images/home/person.png`),
          ticketType: 1,
        },
      ]);
      this.navs = this.navs.concat([
        {
          name: "活动报名",
          btn: "立即报名",
          path: "/activityList?type=活动详情",
          imgUrl: require(`@/assets/images/home/activity.png`),
          ticketType: 4,
        },
      ]);
      this.navs2 = this.navs2.concat([
        {
          name: "团队预约",
          btn: "立即预约",
          imgUrl: require(`@/assets/images/home/team.png`),
        },
      ]);
      this.navs2 = this.navs2.concat([
        {
          name: "临展报名",
          btn: "立即报名",
          path: "/activityList?type=临展详情",
          imgUrl: require(`@/assets/images/home/exhibit.png`),
          ticketType: 5,
        },
      ]);
    },
    // 获取轮播图
    async getCarouselChart() {
      let { rows } = await carouselManagementList({
        pageNum: 1,
        pageSize: 100,
      });
      const arr = [];
      rows.map((v) => {
        if (v.isShow === 1) {
          arr.push(v);
        }
      });
      this.swipes = arr;
    },
    async onNav(item) {
      if (item.name === "团队预约") {
        // this.$toast("请通过温州科技馆官网进行团队预约");
        this.content =
          "<p>请通过温州科技馆官网</p><p>http://www.wzstm.com/</p><p>进行团队预约</p>";
        this.$refs.myTips.init();
      } else {
        this.$store.dispatch("getUserInfo").then(() => {
          if (!this.$store._state.data.info.phonenumber) {
            this.show2 = true;
          } else {
            this.judge(item);
          }
        });
      }
    },
    feedback() {
      // this.content = "功能暂未开放";
      // this.$refs.myTips.init();
      this.$router.push("/myFeedback");
    },
    agree() {
      this.$refs.myNotice.noAppointment();
      this.$router.push(this.item.path);
    },
    onImg(item) {
      if (item.carouselUrl) {
        // let config = {
        //   // 和app协定的scheme
        //   schema: item.carouselUrl,
        //   // iOS下的App在appstore的地址
        //   appstore: item.carouselUrl,
        //   // // 应用宝的地址
        //   yyb: item.carouselUrl
        // }
        // let applink = new AppLink(config)
        // applink.open()
        window.location.href = item.carouselUrl;
      }
    },
    toMyTicket(path) {
      if (path) {
        this.$router.push(path);
      } else {
        this.content = "暂未开启";
        this.$refs.myTips.init();
      }
    },
    async judge(item) {
      if (item.name === "活动报名" || item.name === "临展报名") {
        if (item.path) {
          const { data } = await getCommonConfig({
            ticketType: item.ticketType,
          });
          if (data && data.isOpen) {
            this.$router.push(item.path);
          } else {
            this.content = "暂未开启";
            this.$refs.myTips.init();
          }
        } else {
          this.content = "暂未开启";
          this.$refs.myTips.init();
        }
      } else {
        if (item.path) {
          this.$router.push(item.path);
        }
      }
    },
    modify() {
      this.show = true;
    },
    beforeClose(action) {
      if (action === "confirm") {
        this.$refs.infoForm
          .validate()
          .then(async () => {
            await bind_phone(this.form);
            this.show = false;
            this.form = {};
            this.$store.dispatch("getUserInfo");
          })
          .catch(() => {
            return false;
          });
      } else {
        this.form = {};
        return true;
      }
    },
    openSubscribeSuccess() {
      this.cancel();
    },
    openSubscribeError() {
      this.cancel();
    },
    cancel() {
      this.show3 = false;
      localStorage.setItem("date", this.getDate());
    },
    getDate() {
      let today = new Date();
      let year = today.getFullYear();
      let month = today.getMonth() + 1; // getMonth() 返回的月份是从 0 开始的
      let date = today.getDate();
      return year + "-" + month + "-" + date;
    },
  },
  //销毁定时器
  unmounted() {
    clearInterval(this.timer);
    clearInterval(this.timer2);
  },
};
</script>
<style lang="scss" scoped>
.home {
  background-color: #f5f6fa;
  min-height: 100vh;
  .service_box {
    padding: 10px 0;
    .swipe_box {
      padding: 0 12px;
      .van-swipe {
        height: 136px;
        .van-swipe-item {
          img {
            border-radius: 15px;
            width: 351px;
            height: 136px;
          }
        }
      }
    }
    .nav_box {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 130px;
      .navs {
        padding: 0 12px;
        margin-top: 10px;
        width: 351px;
        height: 98px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      .navs2 {
        width: 100%;
        margin-top: 10px;
        padding: 0 12px;
        display: flex;
        justify-content: space-between;
        img {
          width: 49%;
        }
      }
      .nothing {
        margin: 40px 0 80px;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        > img {
          width: 50%;
        }
        span {
          margin-top: 10px;
          font-size: 16px;
          font-weight: 400;
          color: #9e9e9e;
        }
      }

      .feedback_box {
        padding: 0 12px;
        div {
          position: relative;
          margin: 20px 0 15px;
          font-size: 16px;
          color: #0e2153;
          padding-left: 10px;
          line-height: 17px;
          font-weight: bold;
        }
        div::before {
          content: "";
          position: absolute;
          left: 0;
          width: 3px;
          height: 17px;
          background-color: #0e2153;
          border-radius: 8px;
        }
        img {
          width: 100%;
        }
      }
    }
  }
  .personal_box {
    margin-bottom: 70px;
    .info_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      img {
        width: 70px;
        height: 70px;
      }
      p {
        width: 100%;
        margin-left: 10px;
        font-size: 15px;
        color: #333;
      }
      .btn {
        width: 60px;
        min-width: 60px;
        height: 26px;
        line-height: 26px;
        line-height: 26px;
        font-size: 13px;
        background: linear-gradient(
          180deg,
          rgba(86, 167, 254, 1) 0%,
          rgba(11, 108, 249, 1) 99%
        );
        border-radius: 13px;
        color: #fff;
        text-align: center;
      }
    }
    ::v-deep .van-cell {
      margin-bottom: 5px;
      .van-badge__wrapper {
        display: flex;
        align-items: center;
      }
    }
  }
  .telephone_box {
    position: absolute;
    top: -33.5px;
    width: 100%;
  }
  ::v-deep .van-tabbar {
    height: 70px;
    .van-tabbar-item {
      color: #999;
      .van-badge__wrapper {
        img {
          width: 25px;
          height: 25px;
        }
      }
      .van-tabbar-item__text {
        font-size: 15px;
      }
    }
    .van-tabbar-item--active {
      color: var(--van-tabbar-item-active-color);
    }
  }
  ::v-deep .binding {
    padding: 20px;
    width: auto;
    right: 0;
    > p {
      margin: 0;
      font-size: 15px;
      line-height: 40px;
      color: #333;
      border-bottom: 1px solid #ccc;
      font-weight: 650;
    }
    .van-form {
      .btn_box {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        .van-button {
          margin: 0 20px;
          width: 100px;
          height: 38px;
          line-height: 38px;
          .van-button__text {
            font-size: 14px;
          }
        }
      }
    }
  }
  ::v-deep .authorization {
    .btn_box {
      .btn_authorization {
        position: relative;
        bottom: 0;
        width: 100%;
        height: 38px;
        margin: 10px 0;
        .btn_sty {
          position: relative;
          bottom: 0;
          width: 100%;
          height: auto;
          font-weight: 650;
          color: #fff;
          .van-button__content {
            padding: 10px 0;
          }
          .van-button__text {
            p {
              margin: 0;
              font-size: 14px;
              line-height: 16px;
              > span {
                > span {
                  line-height: 16px;
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
      .cancel {
        width: 100%;
        height: 38px;
      }
    }
  }
}
</style>
